import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import ApiService from './stripeApi';
import { paySubscription as paySubscriptionAction } from '../../actions/subscriptionActions';
import { BsCheckCircle, BsXCircle, BsFillCaretRightSquareFill } from 'react-icons/bs';
import CardForm from './CardForm';
import Message from '../Message';
import Loader from '../Loader';
import { makePrice } from '../../helpers';

import './style.css';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('contentTrs');

  const [error, setError] = useState(null);

  const email = 'abc@aaa.com';
  const stripe = useStripe();
  const elements = useElements();

  let environment_prod = true;
  let planStarter = 'price_1LBazuCwLmJe1ZA1Fo7BkENn';
  let planStandard = 'price_1LBb18CwLmJe1ZA1BdJ1TlyM';
  let planPro = 'price_1LBb2NCwLmJe1ZA1yrWqtfYu';
  let planEasy = 'price_1LBbRZCwLmJe1ZA1YvnophWv';
  let planAccess = 'price_1O4TxPCwLmJe1ZA1EowEhpVw';
  let planFlex = 'price_1O5yc9CwLmJe1ZA1LrMbbfk0';
  let planNewEasy = 'price_1O4TybCwLmJe1ZA1nc6HmAAp';
  if (environment_prod) {
    planStarter = 'price_1LD3BUCwLmJe1ZA1IQVDSBqz';
    planStandard = 'price_1LD3BeCwLmJe1ZA1VTQTMJOn';
    planPro = 'price_1LD3BmCwLmJe1ZA1spQ1WyVZ';
    planEasy = 'price_1LD3BuCwLmJe1ZA1t0HCO32l';
    planAccess = 'price_1O2MmmCwLmJe1ZA1ILphiSk2';
    planFlex = 'price_1O2MkECwLmJe1ZA1SznLChJc';
    planNewEasy = 'price_1O2MkECwLmJe1ZA1lVCsUyvd';
  } else {
    planStarter = 'price_1LBazuCwLmJe1ZA1Fo7BkENn';
    planStandard = 'price_1LBb18CwLmJe1ZA1BdJ1TlyM';
    planPro = 'price_1LBb2NCwLmJe1ZA1yrWqtfYu';
    planEasy = 'price_1LBbRZCwLmJe1ZA1YvnophWv';
    planAccess = 'price_1O4TxPCwLmJe1ZA1EowEhpVw';
    planFlex = 'price_1O5yc9CwLmJe1ZA1LrMbbfk0';
    planNewEasy = 'price_1O4TybCwLmJe1ZA1nc6HmAAp';
  }

  // Suscription
  const Plans = {
    access: {
      title: 'access',
      value: planAccess,
      cost: 20,
    },
    starter: {
      title: 'Starter',
      value: planStarter,
      cost: 20,
      limitPatients: 10,
      priceforEachOtherPatient: 2,
    },
    standard: {
      title: 'Standard',
      value: planStandard,
      cost: 60,
      limitPatients: 50,
      priceforEachOtherPatient: 1.2,
    },
    pro: {
      title: 'Pro',
      value: planPro,
      cost: 250,
      limitPatients: 400,
      priceforEachOtherPatient: 0.65,
    },
    easy: {
      title: 'Easy',
      value: planNewEasy,
      cost: 20,
      limitPatients: 0,
      priceforEachOtherPatient: 45,
    },
    flex: {
      title: 'Flex',
      value: planFlex,
      cost: 1.5,
      limitPatients: 0,
      priceforEachOtherPatient: 1.5,
    },
  };
  const startPrice = 20;
  const starter_LimitPatients = 10;
  const starter_PriceforEachOtherPatient = 2;

  const standardPrice = 60;
  const standard_LimitPatients = 50;
  const standard_PriceforEachOtherPatient = 1.2;

  const proPrice = 250;
  const pro_LimitPatients = 400;
  const pro_PriceforEachOtherPatient = 0.65;

  const treatmentPrice = 20;
  const treatment_PriceforEachOtherPatient = 45;

  const [selectPlan, setSelectPlan] = useState(false);
  const [chosenPlan, setChosenPlan] = useState(false);
  const [accessPlan, setAccessPlan] = useState(Plans.access);
  const [chosenPlanCost, setChosenPlanCost] = useState(false);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState('');
  const [card, setCard] = useState('');
  const [updatedCard, setUpdatedCard] = useState(false);
  const [newCard, setNewCard] = useState(false);

  const [priceYear, setPriceYear] = useState(0);
  const [totalPriceYear, setTotalPriceYear] = useState();
  const [currentSelectPlan, setCurrentSelectPlan] = useState({
    currentPrice: null,
    currentTotalP: null,
    currentPerPatientPrice: null,
  });
  // End Suscription

  const [quoteDraft, setQuoteDraft] = useState('');

  const [couponCode, setCouponCode] = useState('');
  const [couponAmount, setCouponAmount] = useState('');
  const [couponPercentage, setCouponPercentage] = useState('');
  const [couponDuration, setCouponDuration] = useState('');
  // const [cost, setCost] = useState(0)
  // const [discount, setDiscount] = useState(0)

  const paySubscription = useSelector(state => state.paySubscription);
  const {
    error: errorSubscription,
    loading: loadingSubmitSubscription,
    success: successSubscription,
  } = paySubscription;

  // check this link to style Stripe Element in React
  // will need to create a higher level component
  // to import the checkout form and pass the style to it
  // https://stripe.com/docs/payments/quickstart
  // const appearance = {theme: 'stripe', };

  // Page loads
  useEffect(() => {
    ApiService.RetrivePayentMethod()
      .then(response => {
        {
          response.data?.card ? setCard(response.data?.card) : setCard(false);
        }
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    ApiService.RetriveQuote()
      .then(response => {
        {
          response.data?.quote ? setQuoteDraft(response.data?.quote) : setQuoteDraft(false);
        }
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    if (successSubscription) {
      navigate(`/download`);
    }
  }, [successSubscription]);

  // Handle real-time validation errors from the CardElement.
  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSelectPlan = (e, plan) => {
    console.log(chosenPlan);
    console.log(Plans.starter);
    console.log(chosenPlan.value === Plans.starter.value);
    setSelectPlan(true);
    setChosenPlan(plan);
    console.log(plan);

    ApiService.makeQuote({
      email,
      price_id: e.target.value,
    })
      .then(response => {
        console.log(response.data);

        setQuoteDraft(response.data?.quote);
      })
      .catch(error => {});
  };

  function handleCurrentSelectedPlan(currentSuscriptionData) {
    setCurrentSelectPlan({ ...currentSuscriptionData });
    handleSelectPlan();
  }

  const handleSubmitCoupon = e => {
    e.preventDefault();
    // ApiService.makeQuote({
    //     email, coupon_code: couponCode})
    // .then(response => {

    //     setQuoteDraft(response.data?.quote)
    // }).catch(error => {

    // })
    ApiService.RetriveCoupon({
      email,
      coupon_code: couponCode,
    })
      .then(response => {
        if (response.data?.coupon) {
          setCouponAmount(response.data?.coupon?.amount_off);
          setCouponPercentage(response.data?.coupon?.percent_off);
          setCouponDuration(response.data?.coupon?.duration_in_months);
          console.log(response.data?.coupon);
        } else {
          console.log(response.data);
        }
      })
      .catch(error => {});
  };

  // Handle form submission.
  const handleSubmitCard = async event => {
    console.log('submit card');
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    ApiService.saveStripeInfo({
      email,
      payment_method_id: paymentMethod.id,
    })
      .then(response => {
        setCard(paymentMethod.card);
        setNewCard(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSubmitSubscription = async e => {
    let product = accessPlan.value;
    let pricingMethod = chosenPlan.value;
    e.preventDefault();
    // setLoadingSubmitSubscription(true)
    // ApiService.PaySubscription({})
    // .then(response => {
    //     if (response.data.quote.status === 'accepted'){
    //         navigate(`/`)
    //     }
    // }).catch(error => {

    // })
    // setLoadingSubmitSubscription(false)
    dispatch(paySubscriptionAction({ product, pricingMethod, couponCode }));
  };

  const checkoutCost = () => {
    let total = null;
    if (accessPlan?.cost) {
      if (couponAmount) {
        total = accessPlan.cost - couponAmount / 100;
      } else if (couponPercentage) {
        total = accessPlan.cost * (1 - couponPercentage / 100);
      } else {
        total = accessPlan.cost;
      }
    }
    return total;
  };

  /**
     * 
    const handleTotalYearPrice = (event) => {
        setPriceYear(event.target.value);
        if(event.target.value<11){setTotalPriceYear((currentSelectPlan.currentPrice*12 + (event.target.value - event.target.value)))}
        else{setTotalPriceYear((currentSelectPlan.currentPrice + (event.target.value - currentSelectPlan.currentTotalP)*currentSelectPlan.currentPerPatientPrice)*12)}
        
        console.log(currentSelectPlan.currentTotalP)
    };*/

  const handleTotalYearPrice = event => {
    setPriceYear(event.target.value);
    if (event.target.value < 11) {
      setTotalPriceYear(startPrice * 12 + (event.target.value - event.target.value));
    } else {
      setTotalPriceYear(
        (startPrice + (event.target.value - starter_LimitPatients) * starter_PriceforEachOtherPatient) * 12
      );
    }
  };

  return (
    <Row className="pb-3">
      <Row className="justify-content-center mt-3">
        <Col sm={0} md={1} lg={2} className="">
          {' '}
          {/*  sm={0} md={1} lg={2} */}
        </Col>
        <Col sm={1} md={1} lg={1} className="">
          <p>1</p>
        </Col>

        <Col sm={8} md={8} lg={6}>
          <Row className="justify-content-center ">
            <Col sm={12} md={12} lg={10} className="mb-4">
              <Card className="mandatory-subscription-card my-3 p-2 rounded shadow" id="premium-plan">
                <Card.Body className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <Card.Title as="h5" className="mb-2">
                      <strong> Ortho Book Pro starter </strong> ({t('settingsPage.suscription.alwaysSelected')})
                    </Card.Title>
                    <Card.Text className="my-2" style={{ fontSize: '20px' }}>
                      €20/{t('settingsPage.suscription.month')}
                    </Card.Text>
                    <ul className="list-inline mb-2">
                      <li className="list-inline-item">🚀 {t('settingsPage.suscription.platformAccess')}</li>
                      <li className="list-inline-item">🧰 {t('settingsPage.suscription.firstVisit')}</li>
                      <li className="list-inline-item">🔄 {t('settingsPage.suscription.unlimitedVisits')}</li>
                    </ul>
                  </div>
                  <Button className="mandatory-subscription-card-button" disabled>
                    {t('settingsPage.suscription.selectedButton')}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            {/* User prompt to select an additional plan */}
            <Col sm={12} className="user-prompt text-center my-1">
              <p className="call-to-action">{t('settingsPage.suscription.selectPrefferedPlan')}:</p>
            </Col>
          </Row>

          <Row className="justify-content-center ">
            <Col sm={4} md={6} lg={4} className=" p-3">
              <Card
                className={
                  chosenPlan.value === Plans.flex.value
                    ? 'baseCardSelect my-3 p-2 py-3 rounded shadow'
                    : 'my-3 p-2 py-3 rounded shadow'
                }
                id="standard-plan"
              >
                <Card.Title as="div" className="text-center ">
                  <strong>{Plans.flex.title}</strong>
                </Card.Title>
                <Card.Text as="div" className="text-center my-3" style={{ fontSize: 20 }}>
                  €{Plans.flex.cost}/{t('settingsPage.suscription.patient')}/{t('settingsPage.suscription.month')}
                </Card.Text>
                <Card.Text as="div" className="text-center my-1 px-2" style={{ fontSize: 10 }}>
                  {/* <p className=' py-0'> €{Plans.flex.priceforEachOtherPatient}/paziente/mese per ogni paziente aggiuntivo </p> */}
                  <p className=" py-0"> {t('settingsPage.suscription.enableTeleorthodontics')} </p>
                  <p className=" py-0"> {t('settingsPage.suscription.chargedMonthly')} </p>
                </Card.Text>
                <Button
                  className={
                    chosenPlan.value === Plans.flex.value ? 'baseButton mx-3 my-1' : 'baseButtonSelect mx-3 my-1'
                  }
                  value={Plans.flex.value}
                  onClick={e => handleSelectPlan(e, Plans.flex)}
                >
                  {t('settingsPage.suscription.select')}
                </Button>
              </Card>
              {/* <h5 style={{margin: 'auto', textAlign: 'center'}}>€{totalPriceYear.toFixed( 2 )}/year </h5> */}
              {/* <h5 style={{margin: 'auto', textAlign: 'center'}}>€{ ((Plans.access.cost + (Math.max(0, priceYear-Plans.flex.limitPatients) )*Plans.flex.priceforEachOtherPatient) * 12).toFixed( 2 ) }/year </h5> */}
              {/* <h6 style={{margin: 'auto', textAlign: 'center'}}>€{ ((Plans.access.cost)* 12).toFixed( 2 ) }/year +  €{ (((Math.max(0, priceYear-Plans.flex.limitPatients) )*Plans.flex.priceforEachOtherPatient) * 12).toFixed( 2 ) }/year</h6> */}
            </Col>

            <Col sm={4} md={6} lg={4} className=" p-3">
              <Card
                className={
                  chosenPlan.value === Plans.easy.value
                    ? 'baseCardSelect my-3 p-2 py-3 rounded shadow'
                    : 'my-3 p-2 py-3 rounded shadow'
                }
                id="standard-plan"
              >
                <Card.Title as="div" className="text-center ">
                  <strong>{Plans.easy.title}</strong>
                </Card.Title>

                <Card.Text as="div" className="text-center my-3" style={{ fontSize: 20 }}>
                  €{Plans.easy.priceforEachOtherPatient}/{t('settingsPage.suscription.priceforEachOtherPatient')}
                </Card.Text>

                <Card.Text as="div" className="text-center my-1 px-2" style={{ fontSize: 10 }}>
                  <p>{t('settingsPage.suscription.includesYears')}</p>
                  <p>{t('settingsPage.suscription.paidOneOff')}</p>
                </Card.Text>

                {/* <Button className={selectPlan ? 'baseButton mx-3 my-1' : 'baseButtonSelect mx-3 my-1' }  > */}
                <Button
                  className={
                    chosenPlan.value === Plans.easy.value ? 'baseButton mx-3 my-1' : 'baseButtonSelect mx-3 my-1'
                  }
                  value={Plans.easy.value}
                  onClick={e => handleSelectPlan(e, Plans.easy)}
                >
                  {t('settingsPage.suscription.select')}
                </Button>
              </Card>
              {/* <h5 style={{margin: 'auto', textAlign: 'center'}} >€{ ( (Plans.access.cost * 12) + (( priceYear *Plans.easy.priceforEachOtherPatient )/5) ).toFixed( 2 ) }/year </h5> */}
              {/* <h6 style={{margin: 'auto', textAlign: 'center'}} >€{ ( (Plans.access.cost * 12)).toFixed( 2 ) }/year  +  €{ ((( priceYear*Plans.easy.priceforEachOtherPatient )/5) ).toFixed( 2 ) }/year </h6> */}
              {/* <p style={{ margin: 'auto', textAlign: 'center', fontSize: 8}}> * pagamento one-off anticipato del trattamento</p> */}
              {/* <h6 style={{margin: 'auto', textAlign: 'center'}}>€{ (treatmentPrice /priceYear * 12 ) + treatment_PriceforEachOtherPatient }/trattamento </h6> */}
            </Col>
          </Row>

          {/* <Row>
                    <Col sm={6}  md={8} lg={8}>
                        <Row> <p> Stima i tuoi costi, senza vincoli: </p> </Row>
                        <Row style={{ fontSize: 15}}> <p> Quanti nuovi pazienti iniziano il trattamento in un anno?*</p> </Row>
                        <Row style={{ fontSize: 12}}> <p> *Questa stima NON è vincolante, ma solo di supporto per te </p> </Row>
                    </Col>
                    <Col sm={6}  md={4} lg={4}>
                        <Row> <p> </p> </Row>
                        <Row className={'mt-3'}>
                        <Form.Group controlId='__priceTotal' >
                            <Form.Control 
                                className='form-control-sm'
                                inline='true'
                                type='number'
                                placeholder='Numero stimato di pazienti annuali'
                                value={priceYear}
                                onChange={(e) => handleTotalYearPrice(e)}
                            >
                            </Form.Control>
                        </Form.Group>
                        </Row>
                    </Col>
                </Row> */}
        </Col>

        <Col sm={0} md={1} lg={1} className=""></Col>
        <Col sm={0} md={1} lg={2} className=""></Col>
      </Row>

      <Row className="justify-content-center mt-3">
        <Col sm={0} md={1} lg={2} className=""></Col>
        <Col sm={1} md={1} lg={1} className="">
          <p>2</p>
        </Col>
        <Col sm={10} md={8} lg={6} className="shadow p-3">
          <CardForm
            card={card}
            setCard={setCard}
            updatedCard={updatedCard}
            setUpdatedCard={setUpdatedCard}
            handleSubmitCard={handleSubmitCard}
          />
          {updatedCard && newCard && <Message variant="success"> Card Updated</Message>}
        </Col>
        <Col sm={1} md={1} lg={1} className="text-center ml-2">
          <Row>{card && <BsCheckCircle color={'green'} size={30} />}</Row>
          <Row></Row>
        </Col>
        <Col sm={0} md={1} lg={2} className=""></Col>
      </Row>

      <Row className="justify-content-center mt-3">
        <Col sm={0} md={1} lg={2} className=""></Col>
        <Col sm={1} md={1} lg={1} className="">
          <p>3</p>
        </Col>
        <Col sm={10} md={8} lg={6} className="shadow p-3">
          <Form onSubmit={handleSubmitCoupon} className="stripe-form">
            <Form.Label>{t('settingsPage.suscription.applyPromo')}</Form.Label>
            <Row>
              <Col sm={8}>
                <Form.Group controlId="couponCode">
                  <Form.Control
                    className="form-control-sm"
                    inline="true"
                    type="name"
                    placeholder={t('settingsPage.suscription.enterCouponCode')}
                    value={couponCode}
                    onChange={e => setCouponCode(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Button type="submit" className="baseButton btn-sm" inline="true">
                  {' '}
                  {t('settingsPage.suscription.applyButton')}{' '}
                </Button>
              </Col>
            </Row>
          </Form>

          <div className="my-3">
            <Row className="mb-1">
              <Col sm={8}> {t('settingsPage.suscription.cost')} </Col>
              {/* <Col sm={4}> € {quoteDraft?.amount_subtotal ? makePrice(quoteDraft.amount_subtotal) : makePrice(0)}</Col> */}
              <Col sm={4}> € {accessPlan?.cost ? makePrice(accessPlan.cost * 100) : makePrice(0)}</Col>
            </Row>
            <Row className="mb-1">
              <Col sm={8}> {t('settingsPage.suscription.discount')} </Col>
              {/* <Col sm={4}> € {quoteDraft?.total_details?.amount_discount ? makePrice(quoteDraft.total_details.amount_discount) : makePrice(0)}  ({quoteDraft?.total_details?.amount_discount && (quoteDraft.total_details.amount_discount/quoteDraft.amount_subtotal)*100}%)</Col> */}
              <Col sm={4}>
                {' '}
                {couponAmount && `€ ${makePrice(couponAmount)}`} {couponPercentage && `${couponPercentage}%`}
              </Col>
            </Row>
            {couponDuration && (
              <Row className="mb-1">
                <Col sm={8}> </Col>
                {/* <Col sm={4}> € {quoteDraft?.total_details?.amount_discount ? makePrice(quoteDraft.total_details.amount_discount) : makePrice(0)}  ({quoteDraft?.total_details?.amount_discount && (quoteDraft.total_details.amount_discount/quoteDraft.amount_subtotal)*100}%)</Col> */}
                <Col sm={4}>
                  {' '}
                  for {couponDuration && (couponDuration === 1 ? `${couponDuration} mese` : `${couponDuration} mesi`)}
                </Col>
              </Row>
            )}
            <Row className="mb-1">
              <Col sm={8}> {t('settingsPage.suscription.total')} </Col>
              <Col sm={4}> € {accessPlan?.cost ? makePrice(checkoutCost() * 100) : makePrice(0)}</Col>
            </Row>
          </div>

          <Row>
            <Col>
              <Button
                className="mt-3 flex justify-content-between baseButton"
                disabled={card?.last4 && quoteDraft && chosenPlan?.title ? false : true}
                onClick={handleSubmitSubscription}
              >
                {t('settingsPage.suscription.payButton')}
              </Button>
            </Col>
            <Col className="text-start mx-1">{loadingSubmitSubscription && <Loader size="50px"></Loader>}</Col>
          </Row>
        </Col>
        <Col sm={1} md={1} lg={1} className=""></Col>
        <Col sm={0} md={1} lg={2} className=""></Col>
      </Row>
    </Row>
  );
};
export default CheckoutForm;
