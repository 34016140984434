import React, { useState } from 'react';
import axios from 'axios';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FiChevronRight } from 'react-icons/fi';

function LegalDocs(props) {
  const { t } = useTranslation('contentTrs');

  const { userInfo } = useSelector(state => state.userLogin);
  const {
    error: errorPP,
    loading: loadingPP,
    success: successPP,
    patient,
  } = useSelector(state => state.patientProfile);

  const [downloading, setDownloading] = useState(false);

  const downloadDoc = async () => {
    setDownloading(true);

    try {
      const config = {
        headers: {
          // 'Content-type': 'image/jpeg',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const res = await axios.get(`/api/docs/privacydoc/${patient?.id}/`, config);

      let filename = 'privacy_consent' + patient?.firstName + '_' + patient?.firstName + '.' + 'docx';
      const link = document.createElement('a');
      link.href = res.data.data;
      link.target = '_blank';
      // document.body.appendChild(link);
      link.click();
      // link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div>
      <Card className="px-2 py-1 mb-2 shadow radius ">
        <Card.Body className="my-0 pb-0 pt-1">
          <Row>
            <h6>{t('legalDocsTab.title')}</h6>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  downloadDoc();
                }}
                disabled={downloading}
                className="form-button text-end my-1 flex items-center gap-1"
              >
                <span>{t('legalDocsTab.docs.privacyConsent')}</span>
                <FiChevronRight size={18} />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default LegalDocs;
