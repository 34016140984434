import axios from 'axios';
import axiosInstance from '../helpers/axiosConfig';
import {
  PAY_SUBSCRIPTION_REQUEST,
  PAY_SUBSCRIPTION_SUCCESS,
  PAY_SUBSCRIPTION_FAIL,
} from '../constants/subscriptionConstants';

import ApiService from '../components/stripe/stripeApi';

export const paySubscription = data => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAY_SUBSCRIPTION_REQUEST,
    });

    // const { data } = await axiosInstance.put(
    //     `/api/users/profile/update/`,
    //     user,
    //     config
    // )

    ApiService.PaySubscription({ data })
      .then(response => {
        // if (response.data.quote.status === 'accepted'){
        //     navigate(`/`)
        // }
        dispatch({
          type: PAY_SUBSCRIPTION_SUCCESS,
        });
      })
      .catch(error => {});
  } catch (error) {
    dispatch({
      type: PAY_SUBSCRIPTION_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
